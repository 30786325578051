<template>
  <div class="vanInfoCardInlineLink">
    <slot></slot>
    <div class="vanPhone">
      <a href="tel:8559005482" class="cardLink">
        <h2>
          <img src="@/assets/icons/phone-orange.png" class="img-fluid" alt="phone icon" /> (855)
          900-5482
        </h2>
      </a>

      <a href="mailto:vanessa.henry@smartmatchinsurancesolutions.com">
        <h2>
          <img src="@/assets/icons/phone-orange2.png" class="img-fluid" alt="email icon"/>
          vanessa.henry@smartmatchinsurancesolutions.com
        </h2>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "vanInfoCardInlineLink",
  props: ["title"]
};
</script>

<style lang="scss" scoped>
.vanInfoCardInlineLink {
  background: #f7f7f7;
  padding: 2.5em;
  border-radius: 8px;
  margin-top: 3em;
  @media (max-width: 491px) {
    padding: 1.5em;
  }

  h3 {
    text-align: left;
    margin: 0;
    padding-bottom: 1rem;
    font-size: 30px;
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }
}

.vanPhone {
  text-align: left;

  img {
    max-width: 30px;
    margin-right: 0.4em;
    vertical-align: middle;
    @media (max-width: 991px) {
      max-width: 20px;
    }

    @media (max-width: 491px) {
      display: none;
    }
  }

  h2 {
    font-size: 24px;
    color: #ed5c2e;
    text-decoration: none;
    font-weight: 500;
    text-align: left;
    margin: 0;
    line-height: 32px;
    display: inline-block;

    @media (max-width: 991px) {
      font-size: 15px;
      line-height: 0px;
    }

    @media (max-width: 491px) {
      font-size: 12px;
    }
  }

  a {
    text-decoration: none;
    vertical-align: middle;
    @media (max-width: 991px) {
      line-height: 0px;
    }
  }

  .cardLink {
    padding-right: 20px;
    @media (max-width: 991px) {
      display: block;
    }
  }
}
</style>
