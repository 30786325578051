<template>
  <div class="connect">
    <div class="dots">
      <nav-bar-connect></nav-bar-connect>
      <div class="inner-wrap mm-easy">
        <div class="mm-easy-inner">
          <h1>
            SmartConnect powered by
            <strong>SmartMatch</strong>
          </h1>
          <div class="redTextDiv">
            <p class="redText">Medicare consulting for working adults</p>
            <!-- <img src="@/assets/line.png" alt="a red line" /> -->
          </div>
        </div>
      </div>

      <concierge-service-connect></concierge-service-connect>
      <lets-connect-connect></lets-connect-connect>
      <footer-connect></footer-connect>
    </div>
  </div>
</template>

<script>
import NavBarConnect from "@/components/NavBarConnect.vue";
import ConciergeServiceConnect from "@/components/ConciergeServiceConnect.vue";
import LetsConnectConnect from "@/components/LetsConnectConnect.vue";
import FooterConnect from "@/components/FooterConnect.vue";

export default {
  name: "connect",
  components: {
    NavBarConnect,
    ConciergeServiceConnect,
    LetsConnectConnect,
    FooterConnect
  },
  metaInfo: {
    title: "SmartMatch Insurance Solutions | Unbiased Solutions",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Medicare coverage isn’t one-size-fits-all. We help you determine which Medicare path works for you—Medicare Advantage or Medicare Supplement. Plus, any additional coverage for things like prescription medication and more."
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "SmartMatch Insurance Solutions | Unbiased Solutions"
      },
      {
        vmid: "twitter:title",
        property: "twitter:title",
        content: "SmartMatch Insurance Solutions | Unbiased Solutions"
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "Medicare coverage isn’t one-size-fits-all. We help you determine which Medicare path works for you—Medicare Advantage or Medicare Supplement. Plus, any additional coverage for things like prescription medication and more."
      },
      {
        vmid: "twitter:description",
        property: "twitter:description",
        content:
          "Medicare coverage isn’t one-size-fits-all. We help you determine which Medicare path works for you—Medicare Advantage or Medicare Supplement. Plus, any additional coverage for things like prescription medication and more."
      }
    ]
  }
};
</script>

<style lang="scss" scoped>
.redTextDiv {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: relative;
  img {
    width: 54%;
    position: absolute;
    right: 0;
    bottom: -12px;
  }
  p {
    margin: 0;
  }
}
.redText {
  color: #ed5c2e !important;
  font-family: "Just Me Again Down Here", cursive;
  font-size: 44px;
}
.connect {
  background-image: url("/purple-background2.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
}

.dots {
  background-image: url("/dots.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 70%;
}

.mm-easy {
  padding: 45px 0 59px;

  h1 {
    text-align: left;
    margin: 0 0 0.2em 0;
    font-size: 46px;
    font-weight: 400;
    color: #fff;
    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  p {
    max-width: 680px;
    color: #fff;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .mm-easy-inner {
    width: 100%;
    @media (max-width: 1200px) {
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
</style>
