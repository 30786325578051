<template>
  <div class="ConciergeServiceConnect outer-wrap">
    <div class="inner-wrap row">
      <div class="col-1">
        <h2>Medicare is what we do.</h2>
        <p>We know enrolling in Medicare can be a confusing, frustrating, and painstaking process—but it doesn't have to be. With our SmartConnect program, SmartMatch Insurance Solutions is an ally for your Medicare needs.</p>
        <p>SmartMatch Insurance Solutions considers multiple coverage options to find a Medicare plan for your individual health care needs. We're a team of licensed insurance agents who ask you personalized questions to determine the level of coverage you may need—and coverage you don't.</p>

        <VanInfoCard />
      </div>
      <div class="col-2">
        <div class="_form_1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import VanInfoCard from "@/components/VanInfoCard.vue";
export default {
  name: "ConciergeServiceConnect",
  components: {
    VanInfoCard
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://smartmatchinsurancesolutions.activehosted.com/f/embed.php?id=1"
    );
    document.head.appendChild(recaptchaScript);
  }
};
</script>

<style lang="scss" scoped>
.ConciergeServiceConnect {
  background: #ffffff;
  margin: auto;
  padding: 50px 0px 20px;

  .col-1 {
    flex: 0 0 63%;
    max-width: 63%;
    padding-right: 4%;

    @media (max-width: 1200px) {
      flex: 0 0 61%;
      max-width: 61%;
      padding-left: 2%;
    }

    @media (max-width: 991px) {
      flex: 0 0 90%;
      max-width: 90%;
      padding-left: 5%;
      padding-right: 5%;
    }

    h2 {
      margin: 0;
      text-align: left;
      font-weight: 500;
      @media (max-width: 991px) {
        font-size: 24px;
      }
    }

    p {
      line-height: 26px;
      font-size: 18px;
      @media (max-width: 991px) {
        font-size: 16px;
      }
    }
  }

  .col-2 {
    flex: 0 0 33%;
    max-width: 33%;
    @media (max-width: 1200px) {
      flex: 0 0 31%;
      max-width: 31%;
      padding-right: 2%;
    }
    @media (max-width: 991px) {
      flex: 0 0 90%;
      max-width: 90%;
      padding-left: 5%;
      padding-right: 5%;
      text-align: center;
    }
    img {
      @media (max-width: 991px) {
        padding-top: 50px;
      }
    }
  }
}
</style>
