<template>
  <div class="LetsConnect outer-wrap">
    <div class="inner-wrap row">
      <div class="col-1">
        <h2>Understanding your insurance alternatives with SmartConnect</h2>
        <p>SmartConnect is an exclusive program offering creative solutions for those who may not have yet fully explored the benefits of Medicare coverage.</p>
      </div>
    </div>
    <LetsConnectCard src="/seniorcouples.jpg" alt="couple on boardwalk">
      <h3>Individuals</h3>
      <p>Compare plans, policies, carriers, networks, and rates to help you understand the combination of coverage that will best meet your health care and budget needs. Our experience working with clients across the country allows us to identify common problems and solutions. You’ll work directly with our agents and have the opportunity to discuss any questions and concerns you may have.</p>
    </LetsConnectCard>

    <LetsConnectCard src="/broker.jpg" alt="insurance broker at work">
      <h3>Brokers</h3>
      <p>
        The best partner is one that is worthy of trust.
        <strong>SmartMatch</strong> Insurance Solutions is a proven partner with licenses in states across the country. By working directly with us, you’ll be able to find a process and product offering solution that allows you to increase efficiency and, ultimately, help more people.
      </p>
    </LetsConnectCard>

    <LetsConnectCard src="/hrs.jpg" alt="HR Department meeting">
      <h3>HR Departments</h3>
      <p>Looking for a better solution for those employees who reach Medicare-eligibility? We will work with you to establish an efficient research and enrolment path for your valued employees. You’ll be confident that they are receiving the attention they deserve as they transition off of your employer-sponsored health plans.</p>
    </LetsConnectCard>
  </div>
</template>

<script>
import LetsConnectCard from "@/components/LetsConnectCard.vue";
import vanInfoCardInlineLink from "@/components/VanInfoCardInlineLink.vue";

export default {
  name: "LetsConnect",
  components: {
    LetsConnectCard,
    vanInfoCardInlineLink
  }
};
</script>

<style lang="scss" scoped>
.LetsConnect {
  background: #ffffff;
  margin: auto;
  padding: 20px 0px 50px;

  .inner-wrap {
    text-align: center;
  }

  .col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    h2 {
      font-weight: 500;
    }
    p {
      text-align: left;
      font-size: 18px;
      @media (max-width: 1200px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (max-width: 991px) {
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }

  .col-vanInfo {
    flex: 0 0 80%;
    max-width: 80%;
    margin: auto;
    @media (max-width: 991px) {
      flex: 0 0 90%;
      max-width: 90%;
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
</style>
