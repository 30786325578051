<template>
  <div class="vanInfoCard">
    <slot></slot>
    <span class="vanPhone">
      <strong>
        <a href="tel:8559005482" class="cardLink">
          <h2 class="phoneLink">
            <img src="@/assets/icons/phone-orange.png" class="img-fluid" alt="phone icon" /> (855)
            900-5482
          </h2>
        </a>
      </strong>

      <strong>
        <a href="mailto:connect@smartmatchinsurancesolutions.com">
          <h2 class="cardLink emailLink">
            <img src="@/assets/icons/phone-orange2.png" class="img-fluid" alt="email icon" />
            connect@smartmatchinsurancesolutions.com
          </h2>
        </a>
      </strong>
    </span>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: ["title"]
};
</script>

<style lang="scss" scoped>
.vanInfoCard {
  // background: #f7f7f7;
  // padding: 2em;
  border-radius: 8px;
  margin-top: 3em;
  @media (max-width: 491px) {
    // padding: 1.5em;
  }

  h2 {
    text-align: left;
    margin: 0;

    @media (max-width: 991px) {
      font-size: 20px;
    }
  }
  .phoneLink {
    padding-bottom: 0.5em;
  }

  .emailLink {
    @media (max-width: 991px) {
      padding-bottom: 1em;
    }
  }
}

.vanPhone {
  img {
    max-width: 27px;
    margin-right: 0.4em;
    vertical-align: middle;
    @media (max-width: 991px) {
      max-width: 20px;
    }

    @media (max-width: 491px) {
      display: none;
    }
  }

  h2 {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: #ed5c2e;
    text-decoration: none;
    font-weight: 500;
    text-align: left;
    margin: 0;
    line-height: 32px;
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 15px;
    }
    @media (max-width: 350px) {
      font-size: 14px;
    }
  }

  a {
    text-decoration: none;
    vertical-align: middle;
  }
}
</style>
