<template>
  <div class="LetsConnectCard inner-wrap row">
    <div class="col-img">
      <img :src="src" class="img-fluid" :alt="alt" />
    </div>
    <div class="col-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "LetsConnectCard",
  props: {
    src: String,
    alt: String
  }
};
</script>

<style lang="scss" scoped>
.LetsConnectCard {
  padding: 20px 0;

  .col-img {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-img {
    @media (max-width: 1200px) {
      flex: 0 0 23%;
      max-width: 23%;
      padding-left: 2%;
    }

    @media (max-width: 991px) {
      flex: 0 0 90%;
      max-width: 90%;
      margin: auto;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 20px;
    }
  }

  .col-content {
    flex: 0 0 73%;
    max-width: 73%;
    padding-left: 2%;

    @media (max-width: 1200px) {
      flex: 0 0 71%;
      max-width: 71%;
      padding-right: 2%;
    }

    @media (max-width: 991px) {
      flex: 0 0 90%;
      max-width: 90%;
      padding-left: 5%;
      padding-right: 5%;
    }

    h3 {
      text-align: left;
      margin: 0;
      font-size: 24px;
    }

    p {
      text-align: left;
      font-size: 18px;
    }
  }
}
</style>
